
@media (max-width: 1199px) {
    .slider-box .img1 {
        height: 300px;
    }
}

@media (max-width: 992px) {
    .slider-box .img1 {
        height: 240px;
    }
    .gallery-row .img1 {
        height: 140px;
    }
}

@media (max-width: 767px) {
    .slider-box .img1 {
        height: 210px;
    }
    .slider-box .txt1{
        font-size: .875rem;
    }
    .slider-box .date{
        font-size: .625rem;
    }
    .gallery-row .img1 {
        height: 120px;
    }
}

@media (max-width: 575px) {
    .slider-box .img1 {
        height: 170px;
    }
    .gallery-row .img1 {
        height: 90px;
    }
}