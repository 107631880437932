
@font-face {
    font-family: 'BarlowCondensed';
    font-weight: 300;
    src: local('BarlowCondensed'), url(../fonts/BarlowCondensed-Light.ttf) format('opentype');
}

@font-face {
    font-family: 'BarlowCondensed';
    font-weight: 400;
    src: local('BarlowCondensed'), url(../fonts/BarlowCondensed-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'BarlowCondensed';
    font-weight: 500;
    src: local('BarlowCondensed'), url(../fonts/BarlowCondensed-Medium.ttf) format('opentype');
}

@font-face {
    font-family: 'BarlowCondensed';
    font-weight: 600;
    src: local('BarlowCondensed'), url(../fonts/BarlowCondensed-SemiBold.ttf) format('opentype');
}

@font-face {
    font-family: 'BarlowCondensed';
    font-weight: 700;
    src: local('BarlowCondensed'), url(../fonts/BarlowCondensed-Bold.ttf) format('opentype');
}

@font-face {
    font-family: 'BarlowCondensed';
    font-weight: 900;
    src: local('BarlowCondensed'), url(../fonts/BarlowCondensed-Black.ttf) format('opentype');
}


body {
    font-family: "BarlowCondensed", sans-serif !important;
    background-color: #181B1F !important;
    color: #fff !important;
}

body a, 
body a:hover {
    color: inherit;
    text-decoration: none;
    transition: all .4s ease;
    outline: none !important;
}

body p {
    color: #838383;
}

body h6 {
    color: #838383;
    font-weight: 400;
    font-size: .875rem;
}
.link:hover {
    color: #FF7933;
}
.text-main {
    color: #FF7933 !important;
}

.btn.btn1 {
    background: #27005A;
    border: 1px solid #27005A;
    color: #fff;
    width: auto;
    min-width: 160px;
    padding: 14px 24px;
    border-radius: 12px;
    box-shadow: none !important;
    outline: none !important;
    font-weight: 600;
}

.btn.btn1:hover {
    opacity: .75;
}

.btn.btn-orange {
    background: #FF7933;
    border: 1px solid #FF7933;
    color: #fff;
}

.btn.btn-border {
    background: transparent;
    border: 1px solid #363D45;
    color: #fff;
}

.btn.btn-border:hover {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    opacity: 1;
}

.text-83 {
    color: #838383 !important;
}
.text-fe {
    color: #FEFEFE !important;
}
.fs-12 {
    font-size: .75rem !important;
}
.fs-10 {
    font-size: .625rem !important;
}
.fs-20 {
    font-size: 1.25rem !important;
}

.padding {
    padding: 60px 0;
}

.padding2 {
    padding: 20px 0;
}


.main-content {
    padding: 58px 8px 85px;
    margin: auto;
}

.login-box {
    width: 100%;
    max-width: 380px;
}

.ellipsis1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* form1 */
.form.form1 .form-control {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #363D45;
    border-radius: 0;
    color: #fff;
    box-shadow: none !important;
    outline: none !important;
    padding: 14px 0;
}

.form.form1 .form-control::placeholder {
    color: #838383;
}

.form.form1 .form-control:focus {
    border-color: #fff;
}

.form.form1 .form-check-input[type=checkbox] {
    width: 16px;
    height: 16px;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent;
    border-color: #363D45;
}

.form.form1 .form-check-label {
    font-size: .875rem;
}

.form.form1 .form-check-input[type=checkbox]:checked {
    background-color: #FF7933;
    border-color: #FF7933;
}

.comments-input {
    position: relative;
}

.form.form1 .comments-input .form-control {
    background: #181B1F;
    border-radius: 12px;
    color: #fff;
    border: 0;
    padding: 4px 16px;
    padding-right: 55px;
    height: 52px;
}

.comments-input .icon1 {
    background: #7D59E5;
    color: #fff;
    border-radius: 50px;
    width: 36px;
    height: 36px;
    line-height: 33px;
    text-align: center;
    font-size: 1.2rem;
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
}

.comments-input .icon2 {
    color: #fff;
    font-size: 1.2rem;
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
}
.error-text {
    color: red;
    font-size: .875em;
    margin-bottom: 0;
    margin-left: 4px;
    margin-top: 3px;
}

.profile-form {
    max-width: 450px;
    margin: auto;
}

.profile-form .form.form1 .comments-input .form-control {
    color: #D9D9D9;
    padding-left: 0;
    padding-right: 30px;
    border-bottom: 1px solid #363D45;
    border-radius: 0;
}

.profile-form .form.form1 .comments-input .icon2 {
    right: 0;
    color: #D9D9D9;
}


.btn.btn-border {
    background: transparent;
    border: 1px solid #363D45;
    color: #fff;
}

.or:after {
    content: '';
    background: #363D45;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.or p {
    position: relative;
    z-index: 2;
    background: rebeccapurple;
    display: inline-block;
    padding: 0 10px;
    background: #181b1f;
}


.usertype-box {
    border: 1px solid rgb(217 217 217 / 30%);
    border-radius: 12px;
    width: 165px;
    min-width: 165px;
    padding: 24px;
    margin: 11px;
}

.usertype-box .img1 {
    margin-bottom: 12px;
    height: 30px;
}

.usertype-box:hover {
    border-color: #fff;
}


.header-top {
    padding: 15px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background: #181b1f;
}

.header-top .btn.btn1 {
    padding: 7px 16px;
    padding-top: 7px;
    padding-right: 16px;
    padding-bottom: 7px;
    padding-left: 16px;
    min-width: auto;
    font-size: .875rem;
}


.dropdown.dropdown1 button.btn {
    background-color: transparent !important;
    border: 0;
    box-shadow: none !important;
    outline: none !important;
    padding: 0;
}

.dropdown.dropdown1 button.btn::after {
    border: 0;
    width: 8px;
    height: 8px;
    border-left: 2px solid rgb(255 255 255);
    border-bottom: 2px solid rgb(255 255 255);
    transform: rotate(-45deg);
}

.dropdown.dropdown1 button.btn .user1 {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 100px;
}

.dropdown.dropdown1 .dropdown-menu {
    border-color: rgb(0 0 0 / 5%);
    box-shadow: 0 4px 5px rgb(0 0 0 / 6%);
    background: #212229;
    border-radius: 10px;
}

.dropdown.dropdown1 .dropdown-menu .dropdown-item {
    background: transparent !important;
    font-size: .875rem;
    color: #fff;
    padding: 6px 20px;
}

.dropdown.dropdown1 .dropdown-menu .dropdown-item:hover {
    color: #f0b961;
}

.slider-box {
    border-radius: 12px;
    overflow: hidden;
}
.slider-box .img1 {
    width: 100%;
    height: 400px;
    object-fit: cover;
}
.slider-box .txt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgb(0 0 0 / 63%), rgb(0 0 0 / 17%), transparent);
    padding: 20px;
}

/* swiper1 */
.swiper-overflow{
    margin-left: -8px;
    margin-right: -8px;
}
.swiper1 .swiper-slide {
    -webkit-transform: scale(.8) !important;
    transform: scale(.8) !important;
    transition: all .4s ease;
}

.swiper1 .swiper-slide.swiper-slide-active {
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
}

.swiper1 .swiper-pagination {
    bottom: 0 !important;
}

.swiper1 .swiper-wrapper {
    padding-bottom: 32px;
}

.swiper1 .swiper-pagination .swiper-pagination-bullet {
    background: #fff;
    border-radius: 50px;
    width: 7px;
    height: 7px;
    margin: 0 3px;
}

.swiper1 .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 35px;
}

.nav.nav1 .nav-link {
    color: #fff;
    font-size: .875rem;
    border-radius: 6px;
    padding: 6px 16px;
    text-wrap: nowrap;
}

.nav.nav1 .nav-link.active {
    background: #7D59E5;
}

.voting .nav.nav1 .nav-link {
    border-bottom: 1px solid transparent;
    border-radius: 0;
    color: rgb(255 255 255 / 50%);
}

.voting .nav.nav1 .nav-link.active {
    background: transparent;
    border-color: #fff;
    color: #fff;
}

.nav.nav2 .nav-link {
    font-size: 1rem;
    padding: 15px 24px;
    text-wrap: nowrap;
    border: 0;
    border-bottom: 2px solid #363D45;
    border-radius: 0;
    color: #D9D9D9;
    width: 100%;
}

.nav.nav2 .nav-link.active {
    background: transparent;
    border-color: #fff;
    color: #fff;
}



.card.card1 {
    background: #292D32;
    border-radius: 20px;
}
.card.card1  .card-body{
    padding: 20px;
}

.badge1 {
    display: inline-block;
    background: rgb(0 197 102 / 24%);
    color: #00C566;
    border: 1px solid #00C566;
    border-radius: 20px;
    padding: 2px 8px;
}

.player1 .img1 {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 8px;
}


.player-voting {
    border: 1px solid rgb(255 255 255 / 20%);
    border-radius: 12px;
    padding: 15px;
    width: 100%;
    max-width: 125px;
    margin: auto;
}
.player-voting .img1 {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 8px;
}
.player-voting.plyr1 {
    border-color: #0500FF;
}

.player-voting.plyr2 {
    border-color: #FF0000;
}

.player-voting.active {
    border-color: #FF7933;
    border-width: 2px;
    box-shadow: 0 0 20px rgb(255 121 51 / 40%);
}

.progress.progress1 {
    border-radius: 100px;
    border: 1px solid #fff;
    height: 20px;
}

/* menus */
.menus {
    background: #111010;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-bottom: 16px;
    padding-top: 20px;
    z-index: 99;
}

.nav.nav-menu  .nav-link {
    color: #fff;
    font-size: .75rem;
    border-radius: 6px;
    padding: 5px 5px;
    text-wrap: nowrap;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    font-weight: 500;
}

.nav.nav-menu .nav-link.active {
    color: #FF7933;
}

.menus .file-icon {
    background: #FF7933;
    color: #111010;
    width: 52px;
    height: 52px;
    line-height: 50px;
    border-radius: 100%;
    text-align: center;
    position: relative;
    margin: auto;
    margin-top: -52px;
}

.menus .file-icon svg {
    font-size: 1.5rem;
}

.menus .file-icon .noti {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 17px;
    background: #EB3333;
    color: #fff;
    border-radius: 50px;
    font-size: .75rem;
    position: absolute;
    right: -5px;
    bottom: -3px;
    border: 2px solid #111010;
}


.participants-box {
    margin-bottom: 20px;
}
.participants-box .img {
    width: 72px;
    min-width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
}

.participants-box .img .img1 {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100vh;
    background: #181B1F;
    background-image: url('../images/image_1.png');
    background-size: cover;
    transition: all 0.5s ease;
    z-index: 99999999999999;
}

.live-box .live-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    font-size: .875rem;
}

.live-box .live-tag svg {
    color: #E53935;
}
.live-box .video1 {
    height: 100%;
    max-height: 75vh;
    margin-bottom: -5px;
    background: #000;
}

/* .live-box iframe {
    width: 100%;
    height: 100%;
    margin-bottom: -5px;
    background: #000;
} */

.comments-box .img {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
}

.comments-box .img .img1 {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.battle-box {
    margin-top: 20px;
}
.battle-box .img {
    width: 72px;
    min-width: 75px;
    height: 75px;
    border-radius: 12px;
    overflow: hidden;
    margin-right: 12px;
}

.battle-box .img .img1 {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.my-profile .img1 {
    width: 147px;
    height: 147px;
    border-radius: 100%;
    object-fit: cover;
}


.my-profile .img1 {
    width: 147px;
    height: 147px;
    border-radius: 100%;
    object-fit: cover;
}
.profile-links .link {
    padding: 30px 8px;
    display: block;
    border-bottom: 1px solid #363D45;
    font-size: 1.125rem;
}

.profile-links .link .icon1 {
    width: 20px;
    margin-right: 15px;
}

.participants-plus .img1 {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid #04080F;
}

.participants-plus .img1 img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.participants-plus .img1:not(:first-child) {
    margin-left: -20px;
}

.calander-participants .img1 {
    width: 14px;
    min-width: 14px;
    height: 14px;
}

.calander-participants  .img1:not(:first-child) {
    margin-left: -7px;
}
.calander-participants .img1 img {
    margin-top: -16px;
}

.gallery-row .img1 {
    width: 100%;
    height: 220px;
    object-fit: cover;
}

.section1 {
    margin: auto;
    width: 100%;
    height: 100vh;
    background: #181B1F;
    background-image: url('../images/image_1.png');
    background-size: cover;
    transition: all 0.5s ease;
    z-index: 99999999999999;
}



.spin-maindiv {
    background: radial-gradient(rgb(255 255 255 / 30%), transparent);
    height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0.02) 100%);
}

.border-spin{
    background: #fff;
    width: 1px;
    margin: 0 3px;
}

.spin-div {
    height: 170px;
    width: 100%;
    position: relative;
    color: #fff;
    text-align: center;
    padding: 8px 10px;
    /* overflow: hidden; */
    /* background: rgb(255 255 255 / 30%); */
    /* background: radial-gradient(rgb(255 255 255 / 30%), transparent); */
    /* border: 1px solid #000; */
}

.roulette-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: transform 2s ease;
    margin-top: -160px;
}

.spin-item {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    margin: 40px 0;
}

/* Highlight the selected item after spinning */
/* .highlight {
    background-color: yellow;
    font-weight: bold;
} */

/* Spinning effect */
.spin-active .roulette-div {
    animation: spinAnimation .2s linear infinite;
}

@keyframes spinAnimation {
    0% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-20%);
    }
    40% {
        transform: translateY(-40%);
    }
    60% {
        transform: translateY(-60%);
    }
    80% {
        transform: translateY(-80%);
    }
    100% {
        transform: translateY(-100%);
    }
}

.roulette-1 .spin-maindiv {
    height: 150px;
}

.roulette-1 .roulette-div {
    margin-top: -15px;
}
.roulette-1 .spin-div {
    height: 75px;
}
.roulette-1 .spin-item {
    margin: 6px 0;
}

.roulette-1 .border-spin{
    background: #000;
    width: 1px;
    margin: 0;
}


.calendar-box .react-calendar {
    background: #292D32;
    border-radius: 20px;
    border: 0;
}

.calendar-box .react-calendar .react-calendar__tile {
    color: #fff;
    border-radius: 50%;
    height: 48px;
    line-height: 28px;
    background: transparent;
}

.calendar-box .react-calendar .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
    color: rgb(255 255 255 / 50%);
}

.calendar-box .react-calendar .react-calendar__month-view__weekdays__weekday {
    color: rgb(255 255 255 / 60%);
    font-weight: 400;
    text-transform: capitalize;
    border: 0;
}

.calendar-box .react-calendar .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.calendar-box .react-calendar .react-calendar__tile.react-calendar__tile--hasActive {
    background: #ff7933;
    color: #fff;
}

.calendar-box .react-calendar .react-calendar__tile:hover {
    background: rgb(255 121 51 / 50%);
}

.calendar-box .react-calendar .react-calendar__tile.react-calendar__tile--range {
    background: #ff7933;
    border-radius: 0;
}

.calendar-box .react-calendar .react-calendar__tile.react-calendar__tile--range.react-calendar__tile--rangeStart {
    border-radius: 100% 0 0 100%;
}

.calendar-box .react-calendar .react-calendar__tile.react-calendar__tile--range.react-calendar__tile--rangeEnd {
    border-radius: 0 100% 100% 0;
}

.calendar-box .react-calendar .react-calendar__tile.react-calendar__tile--range.react-calendar__tile--rangeBothEnds {
    border-radius: 100%;
}
  
.calendar-box .react-calendar .react-calendar__navigation button {
    color: #fff;
    background: transparent !important;
}

.calendar-box .react-calendar .react-calendar__navigation button:hover {
    color: #ff7933;
}

.calendar-box .react-calendar .react-calendar__tile.react-calendar__century-view__decades__decade,
.calendar-box .react-calendar .react-calendar__tile.react-calendar__decade-view__years__year,
.calendar-box .react-calendar .react-calendar__tile.react-calendar__year-view__months__month {
    border-radius: 10px;
    padding: 0;
}


.liveevent-content {
    width: 100%;
    min-width: 1920px;
    height: 100vh;
    min-height: 1080px;
    background: linear-gradient(270deg, rgb(137, 40, 81) 0%, rgb(0, 0, 0) 42.21%, rgb(0, 0, 0) 57.78%, rgb(21, 86, 133) 99.99%, rgb(102, 102, 102) 100%);
}
.live-section .live1 {
    height: calc(100% - 425px);
}
.live-section .progress.progress1 {
    height: 45px;
}

pre {
    white-space: pre-wrap;
}

  






















/* scrollbar */
.scrollbar
{
  overflow-y: auto;
}
.scrollbar::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #161616;
}

.scrollbar::-webkit-scrollbar
{
  width: 5px;
  height: 4px;
  background-color: #161616;
}

.scrollbar::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #3e3e3e;
}